import React from 'react'
import Image from 'next/image'

const Logo = () => {
    return (
        <>
            <Image
                src="/logo.png"
                className="primary-logo"
                alt="amanco"
                width={171}
                height={24}
            />
            <Image
                src="/logo-reverse.png"
                className="reverse-logo"
                alt="amanco"
                width={171}
                height={24}
            />
        </>
    )
}

export default Logo
