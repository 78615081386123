import React, { useState, useEffect } from 'react'
import Link from 'next/link'

import Logo from '../components/Logo'
import Navigation from '../components/Navigation'

const Footer = () => {
    const [currentYear, setCurrentYear] = useState('')

    useEffect(() => {
        const year = new Date().getFullYear()
        setCurrentYear(year.toString())
    }, [])

    return (
        <div className="bg-[#00001C] py-12 pb-6">
            <div className="container">
                <div>
                    <div className="mx-auto mb-9 w-[171px] flex-shrink-0">
                        <Logo />
                    </div>
                    <div className="mb-11 w-full px-5">
                        {/* Navigation */}
                        <Navigation />
                    </div>
                    <div className="h-[1px] bg-common-white bg-opacity-10"></div>
                    <div className="flex py-6 pb-0 text-interface-300 ipad:flex-col ipad:items-center ipad:text-center">
                        <div>
                            &copy; {currentYear} Amanco Business Group. All
                            rights reserved.
                        </div>
                        <div className="ml-auto ipad:mx-auto">
                            Designed & Developed by{' '}
                            <Link
                                href="https://www.cygnismedia.com/"
                                target="blank"
                                className="text-interface-300 underline"
                            >
                                Cygnis Media
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
