import React from 'react'
import { Link } from 'react-scroll'
import Navigation from '../components/Navigation'
import Logo from '../components/Logo'
import Button from '../components/Button'

const Header = ({ onClick, fixedHeader, stickHeader, active }) => {
    return (
        <>
            <header className={`header ${fixedHeader} ${stickHeader}`}>
                <div className="container">
                    <div className="inner">
                        <div
                            className={`w-[171px] flex-shrink-0 cursor-pointer mobile:w-[130px] ${
                                fixedHeader ? 'mobile:hidden' : ''
                            }`}
                        >
                            <Link
                                to="homeSection"
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={500}
                            >
                                <Logo />
                            </Link>
                        </div>
                        <div className="w-full px-5 ipad:ml-auto ipad:mr-[-35px] ipad:w-auto mobile:mx-auto mobile:mt-3 mobile:w-full mobile:px-0">
                            {/* Navigation */}
                            <Navigation onClick={onClick} active={active} />
                        </div>
                        <div className="w-[138px] flex-shrink-0 ipad:hidden">
                            <Button
                                text="Contact Us"
                                onClick={() => {
                                    onClick('contactSection')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
