import React from 'react'
import Image from 'next/image'

const Arrow = ({ reverse, flip }) => {
    return (
        <div
            className={`relative z-10 -mt-5 ${
                reverse ? '-mb-5 mt-0 rotate-180' : ''
            } ${flip ? 'mb-[-22px] mt-0' : ''}`}
        >
            <div className="container">
                <div className="w-20 ">
                    <Image
                        className={flip ? 'scale-y-[-1]' : ''}
                        src="/up-arrow.png"
                        alt="arrow"
                        width={238}
                        height={81}
                    />
                </div>
            </div>
        </div>
    )
}

export default Arrow
