/* eslint-disable */
import Image from 'next/image'
import emailjs from 'emailjs-com'
import { Formik, Form } from 'formik'
import React, { useCallback, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'

import validationSchema from '@/validations/get-in-touch'

import 'react-toastify/dist/ReactToastify.css'

const GetInTouch = ({ currentSection }) => {
    const [loader, setLoader] = useState(false)

    const email = 'info@amancogrp.com'
    const phoneNumber1 = '+92-21-35046962'
    const phoneNumber2 = '35040015'

    const initialState = {
        firstName: '',
        lastName: '',
        workEmail: '',
        phoneNumber: '',
        message: '',
    }

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        setLoader(true)
        try {
            const templateParams = {
                from_name: `${values.firstName} ${values.lastName}`,
                from_email: values.workEmail,
                phone_number: values.phoneNumber,
                message: values.message,
            }

            // Send the email using emailjs
            const response = await emailjs.send(
                process.env.NEXT_PUBLIC_EMAIL_SERVICE_ID,
                process.env.NEXT_PUBLIC_EMAIL_TEMPLATE_ID,
                templateParams,
                process.env.NEXT_PUBLIC_EMAIL_API_KEY
            )

            if (response.status === 200) {
                resetForm()
                toast.success('Thank you for contacting us !', {})
            }
        } catch (error) {
            // eslint-disable-next-line
            console.error('Error:', error)
        }
        setLoader(false)
    }, [])

    return (
        <section
            id="section-get-in-touch"
            className={`section bg-[url('/hero-bg.png')] bg-center text-common-white ${currentSection}`}
        >
            <div className="container">
                <div className="flex gap-16 ipad:block ipad:gap-0">
                    <div className="w-[404px] flex-shrink-0 ipad:mb-11 ipad:w-full ipad:border-b ipad:border-primary-900">
                        <h2 className="mb-4">Get in touch</h2>
                        <p className="mb-12 text-interface-300 ipad:mb-6">
                            Contact us today to learn more about our products
                            and services, or to request a quote.
                        </p>
                        <div className="ipad:mb-10">
                            <ul>
                                <li className="mb-6 flex ">
                                    <i className="mr-3 h-6 w-6 flex-shrink-0">
                                        <Image
                                            src="/icons/email-icon.svg"
                                            alt="email"
                                            width={24}
                                            height={24}
                                        />
                                    </i>
                                    <span>
                                        Email:
                                        <a
                                            className="ml-2 text-common-white underline"
                                            href={`mailto:${email}`}
                                        >
                                            {email}
                                        </a>
                                    </span>
                                </li>
                                <li className="mb-6 flex ">
                                    <i className="mr-3 h-6 w-6 flex-shrink-0">
                                        <Image
                                            src="/icons/phone-icon.svg"
                                            alt="phone"
                                            width={24}
                                            height={24}
                                        />
                                    </i>
                                    <span>
                                        Phone:
                                        <a
                                            className="ml-2 text-common-white"
                                            href={`tel:${phoneNumber1}`}
                                        >
                                            {phoneNumber1}
                                        </a>
                                        ,
                                        <a
                                            className="ml-2 text-common-white"
                                            href={`tel:${phoneNumber2}`}
                                        >
                                            {phoneNumber2}
                                        </a>
                                    </span>
                                </li>
                                <li className="mb-6 flex">
                                    <i className="mr-3 h-6 w-6 flex-shrink-0">
                                        <Image
                                            src="/icons/location-icon.svg"
                                            alt="location"
                                            width={30}
                                            height={30}
                                        />
                                    </i>
                                    <span>
                                        Plot No. 18, Sector-28, Korangi
                                        Industrial Area, Karachi-74900.
                                        Pakistan.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* Contact form */}
                    <div className="w-full">
                        <div>
                            <p className="mb-6 font-medium">
                                Please fill out the form below and we&apos;ll
                                get back to you soon.
                            </p>
                        </div>
                        <Formik
                            onSubmit={handleSubmit}
                            initialValues={initialState}
                            validationSchema={validationSchema}
                            validateOnBlur={false}
                        >
                            {({ values, errors, touched }) => {
                                return (
                                    <Form noValidate>
                                        <div className="grid grid-cols-2 gap-6 mobile:grid-cols-1 mobile:gap-0">
                                            <div className="form-group">
                                                <label>First Name:</label>
                                                <input
                                                    className={`form-control ${
                                                        touched.firstName &&
                                                        errors.firstName
                                                            ? 'error'
                                                            : ''
                                                    }`}
                                                    type="text"
                                                    id="firstName"
                                                    placeholder="Enter your first name"
                                                    value={values.firstName}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Last Name:</label>
                                                <input
                                                    className={`form-control ${
                                                        touched.lastName &&
                                                        errors.lastName
                                                            ? 'error'
                                                            : ''
                                                    }`}
                                                    type="text"
                                                    id="lastName"
                                                    placeholder="Enter your last namee"
                                                    value={values.lastName}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-6 mobile:grid-cols-1 mobile:gap-0">
                                            <div className="form-group">
                                                <label>Work Email:</label>
                                                <input
                                                    className={`form-control ${
                                                        touched.workEmail &&
                                                        errors.workEmail
                                                            ? 'error'
                                                            : ''
                                                    }`}
                                                    type="email"
                                                    id="workEmail"
                                                    placeholder="Enter your work email address"
                                                    value={values.workEmail}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Phone Number:</label>
                                                <input
                                                    className={`form-control ${
                                                        touched.phoneNumber &&
                                                        errors.phoneNumber
                                                            ? 'error'
                                                            : ''
                                                    }`}
                                                    type="tel"
                                                    id="phoneNumber"
                                                    placeholder="Enter your phone number"
                                                    value={values.phoneNumber}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="form-group">
                                                <label>Message:</label>
                                                <textarea
                                                    className={`form-control ${
                                                        touched.message &&
                                                        errors.message
                                                            ? 'error'
                                                            : ''
                                                    }`}
                                                    id="message"
                                                    placeholder="Start typing your message"
                                                    value={values.message}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex items-center mobile:flex-col">
                                            <div className="mr-3 w-[107px] flex-shrink-0 mobile:m-0 mobile:w-full ">
                                                <button
                                                    className="btn "
                                                    type="submit"
                                                >
                                                    {!loader && (
                                                        <span>Submit</span>
                                                    )}
                                                    {loader && (
                                                        <div>
                                                            <div className="loader">
                                                                Loading...
                                                            </div>
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                            {/* Toast */}
                                            <ToastContainer
                                                position="bottom-right"
                                                autoClose={5000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="colored"
                                            />
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GetInTouch
