import React from 'react'
import Image from 'next/image'

const OurPartners = ({ currentSection }) => {
    const logos = [
        {
            name: 'Troy',
            path: 'https://www.troygroup.com/',
            src: '/our-partners/1.jpg',
        },
        {
            name: 'AB Corp',
            path: 'https://abcorp.com/',
            src: '/our-partners/2.jpg',
        },
        {
            name: 'Favini',
            path: 'https://www.favini.com/en/home/',
            src: '/our-partners/3.jpg',
        },
        {
            name: 'Flint Group',
            path: 'https://www.flintgrp.com/',
            src: '/our-partners/5.jpg',
        },
        {
            name: 'Suzano',
            path: 'https://www.suzano.com.br/en/',
            src: '/our-partners/4.jpg',
        },

        {
            name: 'Precision Link',
            path: 'https://precisioninkcorp.com/',
            src: '/our-partners/6.jpg',
        },
    ]
    return (
        <section
            id="section-our-partners"
            className={`section ${currentSection}`}
        >
            <div className="container">
                <div className="flex items-center gap-16 mobile:flex-col mobile:gap-0">
                    <div className="w-3/6 mobile:w-full">
                        <h2>Our Partners</h2>
                        <p className="pr-4 text-interface-700">
                            At Amanco, we&apos;re proud to partner with some of
                            the most respected names in the industry.
                        </p>
                    </div>
                    <div className="w-3/6 mobile:w-full">
                        <ul className="grid grid-cols-3 mobile:grid-cols-2">
                            {logos.map((logo, index) => (
                                <li key={index}>
                                    <a
                                        href={logo.path}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Image
                                            src={logo.src}
                                            alt={logo.name}
                                            width={160}
                                            height={90}
                                        />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurPartners
