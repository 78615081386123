import Image from 'next/image'

const About = ({ currentSection }) => {
    return (
        <section id="section-about" className={` section ${currentSection}`}>
            <div className="container overflow-visible">
                <div className={`flex ipad:flex-col `}>
                    <div
                        className={`graphic animate__animated animate__slideInLeft w-3/6 opacity-0  ipad:w-full  ipad:opacity-100`}
                    >
                        <Image
                            src="/about.png"
                            alt="arrow"
                            width={540}
                            height={710}
                            className="ipad:mx-auto ipad:w-full ipad:max-w-[600px]"
                        />
                    </div>
                    <div className="flex w-3/6 items-center pl-[50px] pr-[78px] lg:px-3 ipad:mt-14 ipad:w-full">
                        <div className="animate__animated animate__slideInRight opacity-0 ipad:opacity-100">
                            <h2 className="section-heading">About Us</h2>
                            <p className="text-interface-700">
                                Established in 1985, Amanco is a diversified
                                distribution company supplying raw materials. We
                                cater to the packaging, security printing,
                                banking and paper markets. We have exclusive
                                agency and distribution rights with some of the
                                world&apos;s leading companies.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
