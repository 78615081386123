import Head from 'next/head'
import { Element, scroller } from 'react-scroll'
import React, { useEffect, useState } from 'react'

import Hero from '../components/Hero'
import About from '../components/About'
import Arrow from '../components/Arrow'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import GetInTouch from '../components/GetInTouch'
import OurPartners from '../components/OurPartners'
import IndustriesServed from '../components/IndustriesServed'

const IndexPage = () => {
    const [active, setActive] = useState('homeSection')
    const [currentSection, setcurrentSection] = useState()
    const [isScrolledToScreenHeight, setIsScrolledToScreenHeight] =
        useState(false)
    const [isScrolledToInitialHeight, setIsScrolledToInitialHeight] =
        useState(false)

    const scrollToElement = (targetElement) => {
        setActive(targetElement)
        scroller.scrollTo(targetElement, {
            duration: 500,
            smooth: true,
            offset: 0, // Adjust the offset if needed
            delay: 0,
            isDynamic: true,
        })
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY
            const screenHeight = window.innerHeight
            const sections = document.querySelectorAll('.section')
            let activeSection = ''

            if (scrollPosition > 100) {
                setIsScrolledToInitialHeight(true)
            } else {
                setIsScrolledToInitialHeight(false)
            }

            if (scrollPosition >= screenHeight) {
                setIsScrolledToScreenHeight(true)
            } else {
                setIsScrolledToScreenHeight(false)
            }

            sections.forEach((section) => {
                const sectionTop = section.offsetTop
                const sectionBottom = sectionTop + section.offsetHeight

                if (
                    scrollPosition >= sectionTop - 300 &&
                    scrollPosition < sectionBottom
                ) {
                    if (!section.classList.contains('active')) {
                        section.classList.add('active')
                    }
                }
            })

            setcurrentSection(activeSection)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    return (
        <>
            <Head>
                <title>{`Home | ${process.env.NEXT_PUBLIC_APP_NAME}`}</title>
            </Head>
            <Layout>
                <div>
                    <div>
                        <Header
                            active={active}
                            fixedHeader={
                                isScrolledToInitialHeight ? 'fixed-header' : ''
                            }
                            stickHeader={
                                isScrolledToScreenHeight ? 'sticky-header' : ''
                            }
                            onClick={(event) => scrollToElement(event)}
                        />
                    </div>

                    {/* Hero Section */}
                    <div name="homeSection">
                        <Hero onClick={() => scrollToElement('aboutSection')} />
                    </div>
                    <div>
                        <Arrow />
                    </div>
                    {/* About */}
                    <Element name="aboutSection">
                        <About currentSection={currentSection} />
                    </Element>
                    {/* Industrial Served */}
                    <div>
                        <Arrow reverse />
                    </div>
                    <Element name="industriesSection">
                        <IndustriesServed currentSection={currentSection} />
                    </Element>
                    {/* Our Partners */}
                    <Element name="partnerSection">
                        <OurPartners currentSection={currentSection} />
                    </Element>
                    <div>
                        <Arrow flip />
                    </div>
                    {/* Get In touch */}
                    <Element name="contactSection">
                        <GetInTouch currentSection={currentSection} />
                    </Element>
                    {/* Footer */}
                    <Footer />
                </div>
            </Layout>
        </>
    )
}

export default IndexPage
