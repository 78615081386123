import React from 'react'
import { Link } from 'react-scroll'
const Navigation = ({ onClick, active }) => {
    const menuItems = [
        { text: 'Home', path: '/', name: 'homeSection', active: true },
        { text: 'About', path: '/about', name: 'aboutSection' },
        {
            text: 'Industries Served',
            path: '/contact',
            name: 'industriesSection',
        },
        { text: 'Partners', path: '/partners', name: 'partnerSection' },
    ]

    return (
        <nav>
            <ul className="navigation">
                {menuItems.map((menuItem, index) => (
                    <li
                        key={index}
                        className={active === menuItem.name ? 'active' : ''}
                        onClick={() => onClick(menuItem.name)}
                    >
                        <Link
                            activeClass="active"
                            to={menuItem.name}
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                        >
                            <span>{menuItem.text}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Navigation
