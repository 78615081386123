import * as Yup from 'yup'

export default Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    workEmail: Yup.string()
        .email('Invalid email address')
        .required('Work Email is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    message: Yup.string().required('Message is required'),
})
