import React from 'react'
import Image from 'next/image'

const Hero = ({ onClick }) => {
    return (
        <section className="hero-section section active bg-[url('/hero-bg.png')]">
            <span className="lines bg-[url('/lines.png')]"></span>
            <div className="content animate__animated animate__repeat-1 animate__slideInDown animate__delay-1s">
                <div className="container ">
                    <h1>
                        Empowering <br /> Industries with Quality
                    </h1>
                    <p>
                        Amanco - Diversified Distribution Company Catering to
                        Packaging, Security Printing, Banking and Paper Markets.
                    </p>
                </div>
            </div>
            <span
                className="absolute bottom-8 left-0 right-0 mx-auto w-8 cursor-pointer desktop:animate-pulse mobile:w-5"
                onClick={onClick}
            >
                <Image
                    src="/scroll-to-arrow.svg"
                    alt="arrow"
                    width={238}
                    height={81}
                />
            </span>
        </section>
    )
}

export default Hero
