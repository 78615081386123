import React from 'react'
import Image from 'next/image'

const IndustriesServed = ({ currentSection }) => {
    const industriesLIst = [
        {
            heading: 'Security Printing & Identity',
            image: '/industries/security-printing.jpg',
            featureList: [
                {
                    text: 'Security papers',
                },
                { text: 'Security inks' },
                {
                    text: 'MICR toners and cartridges for cheque printing',
                },
                {
                    text: 'Passport covers',
                },
                {
                    text: 'Government issued ID cards',
                },
                {
                    text: 'Machinery and equipment',
                },
            ],
        },
        {
            heading: 'Paper Products',
            image: '/industries/paper-product.jpg',
            featureList: [
                {
                    text: 'Offset paper in reels and sheets',
                },
                {
                    text: 'A4 paper in 70/75/80 gsm',
                },
                {
                    text: 'Thermal paper',
                },
                {
                    text: 'Coated and metallized papers',
                },
                {
                    text: 'Paperboard for luxury printing',
                },
                {
                    text: 'Anti-counterfeit paper',
                },
            ],
        },
        {
            heading: 'Banking and Financial',
            image: '/industries/banking-and-financial.jpg',
            featureList: [
                {
                    text: 'ATM, debit and credit cards',
                },
                {
                    text: 'MICR Printers',
                },
                {
                    text: 'A4 paper',
                },
            ],
        },
        {
            heading: 'Packaging',
            image: '/industries/packaging.jpg',
            featureList: [
                {
                    text: 'Sheetfed inks',
                },
                {
                    text: 'Coatings',
                },
                {
                    text: 'UV inks',
                },
            ],
        },
    ]

    return (
        <section
            className={` secondary-section section  ${currentSection}`}
            id="section-industrial-served"
        >
            <div className="container mx-auto overflow-visible">
                <div className={``}>
                    <div className="animate__animated animate__fadeInDown mb-12 text-center opacity-0">
                        <h2 className="">Industries Served</h2>
                        <p className="mx-auto max-w-[720px] text-interface-700  ">
                            At Amanco, we cater to a diverse range of
                            industries, including security printing and
                            identity, paper products, banking, and packaging.
                        </p>
                    </div>
                    <div>
                        <ul className="industries-list">
                            {industriesLIst.map((item, index) => (
                                <li
                                    className={` item animate__animated animate__fadeInDown opacity-0 `}
                                    key={index}
                                >
                                    <div className="content w-2/4 mobile:w-full">
                                        <div>
                                            <h3 className="text-[#18191F]">
                                                {item.heading}
                                            </h3>
                                            <ul className="features">
                                                {item.featureList.map(
                                                    (list, indexChild) => (
                                                        <li key={indexChild}>
                                                            {list.text}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="w-2/4 mobile:w-full">
                                        <div className="image">
                                            <Image
                                                src={item.image}
                                                alt={item.heading}
                                                width={523}
                                                height={304}
                                            />
                                        </div>
                                    </div>
                                </li>
                            ))}
                            <li></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IndustriesServed
